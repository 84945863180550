import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`So you want to make your website more accessible? Great! And you've heard about
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA"
      }}>{`ARIA`}</a>{`, which stands for Accessible Rich Internet
Applications. Just slap some ARIA attributes on all HTML elements and your page is accessible? Wrong!`}</p>
    <p>{`If you don't know what you're doing, you'll end up making your website even less accessible! There's a reason why
the first rule of ARIA is: `}<strong>{`No ARIA is better than Bad ARIA!`}</strong>{` Use native HTML elements and attributes
with the semantics and behaviour you require.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "63.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAFjtM2jmkP/xAAbEAACAwADAAAAAAAAAAAAAAABEgACExEhIv/aAAgBAQABBQLVpfhRiYO6q8t5P//EABURAQEAAAAAAAAAAAAAAAAAAAAh/9oACAEDAQE/AUf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAACAQQDAAAAAAAAAAAAAAAAATECEBGBEkFx/9oACAEBAAY/AtC47IwNx4ijq3//xAAZEAEBAQEBAQAAAAAAAAAAAAABETEAIVH/2gAIAQEAAT8hsZcdokRdb0NafK8W4IUw6I4dnVAfO//aAAwDAQACAAMAAAAQ4B//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFR/9oACAEDAQE/EHGtJg//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAECAQE/EAxu3//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExQYFxocH/2gAIAQEAAT8QM2MgG7W4bYjBoAc+p3fAfZrroASh13Lt9iqN5y+cQ2fyB4n/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A woman sits on the floor, balancing a laptop on her knees. She looks confused at the man next to her.",
          "title": "A woman sits on the floor, balancing a laptop on her knees. She looks confused at the man next to her.",
          "src": "/static/d4e56c8e213c409c18497393ef2b4a01/e5166/pexels-ketut-subiyanto-confused-couple.jpg",
          "srcSet": ["/static/d4e56c8e213c409c18497393ef2b4a01/f93b5/pexels-ketut-subiyanto-confused-couple.jpg 300w", "/static/d4e56c8e213c409c18497393ef2b4a01/b4294/pexels-ketut-subiyanto-confused-couple.jpg 600w", "/static/d4e56c8e213c409c18497393ef2b4a01/e5166/pexels-ketut-subiyanto-confused-couple.jpg 1200w", "/static/d4e56c8e213c409c18497393ef2b4a01/b17f8/pexels-ketut-subiyanto-confused-couple.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Ketut Subiyanto / pexels.com`}</em></p>
    <p>{`Let's take a look at use cases for ARIA and common mistakes you should avoid.`}</p>
    <h2>{`ARIA, what is it good for?`}</h2>
    <p>{`ARIA is a set of `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles"
      }}>{`roles`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes"
      }}>{`attributes`}</a>{` that can help you to make
web content more accessible to people with disabilities. The latest version `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria-1.2/"
      }}>{`WAI-ARIA 1.2`}</a>{`
was released by the W3C in December 2021.`}</p>
    <p>{`Persons with certain types of disabilities use assistive technologies to interact with web content. For example, blind users
navigate websites with the help of screen readers. People with motor disabilities may rely on speech recognition software
to interact with a website.`}</p>
    <p>{`Web developers can use ARIA roles and attributes to convey semantics of the content to users of assistive technologies.
You can set `}<InlineCode mdxType="InlineCode">{`aria-expanded`}</InlineCode>{` on an element to indicate if a control is expanded or collapsed.
The `}<InlineCode mdxType="InlineCode">{`aria-selected`}</InlineCode>{` attribute indicates if, e.g., a tab is currently selected or not.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<h3>
    <button type="button" aria-expanded="false" aria-controls="section1" id="header1">
        Personal Information
    </button>
</h3>
<div id="section1" role="region" aria-labelledby="header1">
    <!-- Panel content that is shown/hidden via button -->
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In general, only use ARIA for custom widgets like, e.g., an expansion panel. Check out the
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/"
      }}>{`ARIA Authoring Practices Guide`}</a>{`, which describes how to apply accessibility semantics
to common design patterns and widgets.`}</p>
    <h2>{`Bad ARIA practices`}</h2>
    <p>{`I've audited hundreds of websites for accessibility. One thing I've noticed is: Many web developers apparently have no
idea what they're doing! They clutter the DOM with ARIA roles and attributes as if there was no tomorrow. I'll show you
some of the most common mistakes I've encountered.`}</p>
    <h3>{`Using `}<InlineCode mdxType="InlineCode">{`aria-hidden="true"`}</InlineCode>{` on a focusable element`}</h3>
    <p>{`ARIA gives you full control over the `}<a parentName="p" {...{
        "href": "https://web.dev/the-accessibility-tree/"
      }}>{`accessibility tree`}</a>{`, which is a modified
version of the DOM that is presented to assistive technolgies. The `}<InlineCode mdxType="InlineCode">{`aria-hidden`}</InlineCode>{` attribute allows
you to remove an element from the accessibility tree and thereby hide it from screen reader users.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<a href="https://www.stuff.com" aria-hidden="true">Interesting Stuff</a>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`The problem is: Removing a focusable element like a link from the accessibility tree does not remove it from tab order.
A screen reader user tabbing through the website will arrive at the link and hear “empty” or something similar. Please,
don't do this!`}</p>
    <h3>{`Setting an `}<InlineCode mdxType="InlineCode">{`aria-label`}</InlineCode>{` different from the visible label`}</h3>
    <p>{`Imagine you're a university student in Vienna and want to register for a lecture online. Because of your
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Tremor"
      }}>{`hand-tremor`}</a>{`, you use voice recognition software to operate the website. You
encounter the following web form in German:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<form action="">
    <div class="form-field">
        <label for="firstname">Vorname</label>
        <input type="text" aria-label="First Name" name="firstname" id="firstname">
    </div>
    <div class="form-field">
        <label for="lastname">Nachname</label>
        <input type="text" aria-label="Last Name" name="lastname" id="lastname">
    </div>
    <!-- Other fields -->
</form>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`To fill out the form, you want to focus on the first input field with the visible label `}<span lang="de">{`“Vorname”`}</span>{`.
Therefore, you speak the voice command `}<em lang="de">{`“Vorname klicken”`}</em>{` (in German), but nothing happens.
What is going on?`}</p>
    <p>{`As the code above shows, the `}<InlineCode mdxType="InlineCode">{`label`}</InlineCode>{` element is programmatically associated with the input field using
the `}<InlineCode mdxType="InlineCode">{`for`}</InlineCode>{` attribute. Unfortunately, the `}<InlineCode mdxType="InlineCode">{`aria-label`}</InlineCode>{` attribute overwrites
the `}<InlineCode mdxType="InlineCode">{`input`}</InlineCode>{` element's accessible name with “First Name”. This means, that the control's name does not
include the visible label.`}</p>
    <p>{`So the form is not easy to operate for voice recognition users and it also represents a clear
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/label-in-name.html"
      }}>{`WCAG violation`}</a>{`. Fun fact: This is an actual example
from the German website of a large university in Vienna.`}</p>
    <h3>{`The `}<InlineCode mdxType="InlineCode">{`aria-labelledby`}</InlineCode>{` attribute references an ID that doesn't exist`}</h3>
    <p>{`You can use the `}<InlineCode mdxType="InlineCode">{`aria-labelledby`}</InlineCode>{` attribute to label an element by referencing another element. Its
value should be a list of one or more ID values referencing the elements that label the current element. Here's an example:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<nav aria-labelledby="sidenav-title">
    <h2 id="sidenav-title">Related Content</h2>
    <ul>
        <!-- List of links -->
    </ul>
</nav>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`If there were no element with the id “sidenav-title”, then the `}<InlineCode mdxType="InlineCode">{`nav`}</InlineCode>{` element would have no label.
This would result in a less than optimal experience for screen reader users. Therefore, always check that the elements
referenced by `}<InlineCode mdxType="InlineCode">{`aria-labelledby`}</InlineCode>{` are present in the DOM.`}</p>
    <h3>{`Navigation Menu with `}<InlineCode mdxType="InlineCode">{`role="menubar"`}</InlineCode>{` or `}<InlineCode mdxType="InlineCode">{`role="menu"`}</InlineCode></h3>
    <p>{`Many websites include site navigation that is styled to look like a menubar with expandable sections. Because of the
visual appearance, a lot of web developers think that they should apply the following semantic roles:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<ul role="menubar">
    <li role="none">
        <a role="menuitem" href="/">Home</a>
    </li>
    <li role="none">
        <a role="menuitem" aria-haspopup="true" aria-expanded="false" href="#">About</a>
        <ul role="menu" aria-label="About">
            <li role="none">
                <a role="menuitem" href="/overview">Overview</a>
            </li>
            <li role="none">
                <a role="menuitem" href="/administration">Administration</a>
            </li>
        </ul>
    </li>
</ul>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`The problem with this markup is the expectations it creates. The roles `}<InlineCode mdxType="InlineCode">{`menubar`}</InlineCode>{`
and `}<InlineCode mdxType="InlineCode">{`menu`}</InlineCode>{` should be used for menu widgets that offer a list of choices to the user,
such as a set of actions. These menus should behave like native operating system menus, which means: They
should `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/menu/"
      }}>{`implement a specific set of keyboard interactions`}</a>{`,
including navigation with arrow keys.`}</p>
    <p>{`If you create a site navigation with, e.g., `}<InlineCode mdxType="InlineCode">{`role="menubar"`}</InlineCode>{`, then screen reader users
will expect to navigate the menu with the arrow keys. When this doesn't work, they'll be confused.
A better alternative is to implement the
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/example-index/disclosure/disclosure-navigation.html"
      }}>{`disclosure pattern`}</a>{` for
site navigation with expandable groups.`}</p>
    <h2>{`Basic rules for using ARIA`}</h2>
    <p>{`As we've seen, a lot can go wrong with ARIA. Here's a list of basic rules that will make your life easier:`}</p>
    <ol>
      <li parentName="ol">{`Go for native HTML elements first.`}</li>
      <li parentName="ol">{`Do not change native semantics, unless you really have to.`}</li>
      <li parentName="ol">{`Use ARIA for custom widgets and strictly follow the Authoring Practices Guide.`}</li>
      <li parentName="ol">{`All interactive ARIA controls must be usable with the keyboard.`}</li>
      <li parentName="ol">{`Test your code with assistive technologies like screen readers and voice recognition software.`}</li>
    </ol>
    <h2>{`Useful Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA"
        }}>{`ARIA (MDN)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/ARIA/apg/"
        }}>{`ARIA Authoring Practices Guide`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://web.dev/the-accessibility-tree/"
        }}>{`The Accessibility Tree`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      